import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtToken } from './DataContext';
import { UserContext } from './UserContext';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [claims, setClaims] = useState(localStorage.getItem('claims') ? JSON.parse(localStorage.getItem('claims')) : []);
  const [activeClaims, setActiveClaims] = useState([]);
  const [activeDealerClaims, setActiveDealerClaims] = useState([]);
  const [inactiveClaims, setInactiveClaims] = useState([]);
  const [inactiveDealerClaims, setInactiveDealerClaims] = useState([]);
  const [expiredClaims, setExpiredClaims] = useState([]);
  const [pendingClaims, setPendingClaims] = useState([]);
  const [pendingDealerClaims, setPendingDealerClaims] = useState([]);
  const [expiredDealerClaims, setExpiredDealerClaims] = useState([]);
  const [dealerClaims, setDealerClaims] = useState([]);
  const [individualClaims, setIndividualClaims] = useState([]);
  const [previousLocation, setPreviousLocation] = useState(
    localStorage.getItem('previousLocation') ? JSON.parse(localStorage.getItem('previousLocation')) : '',
  );

  useEffect(() => {
    localStorage.setItem('previousLocation', JSON.stringify(previousLocation));
  }, [previousLocation]);

  // Add useEffect to fetch data
  useEffect(() => {
    const fetchClaims = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_COMMON}getClaims`, { userID },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // Store the entire claims array from the response in the context
        setClaims(response.data);
        setDealerClaims(response.data.DealerClaimRecords);
        setIndividualClaims(response.data.IndividualClaimRecords);
      } catch (error) {
        console.error(error);
      }
    };
    if (token && userID) {
      fetchClaims();
    }
  }, [token, userID]);

  async function apendIndividualRecords() {
    if (claims && claims.IndividualClaimRecords) {
      setActiveClaims(individualClaims.filter((claim) => claim.Status === 'Active'));
      setInactiveClaims(individualClaims.filter((claim) => claim.Status === 'Inactive'));
      setExpiredClaims(individualClaims.filter((claim) => claim.Status === 'Expired'));
      setPendingClaims(individualClaims.filter((claim) => claim.Status === 'Pending'));
    }

  }
  async function apendDealerRecords() {
    if (claims && claims.DealerClaimRecords) {
      setActiveDealerClaims(dealerClaims.filter((claim) => claim.Status === 'Active'));
      setInactiveDealerClaims(dealerClaims.filter((claim) => claim.Status === 'Inactive'));
      setExpiredDealerClaims(dealerClaims.filter((claim) => claim.Status === 'Expired'));
      setPendingDealerClaims(dealerClaims.filter((claim) => claim.Status === 'Pending'));
    }

  }
  useEffect(() => {
    async function dataUpdate() {
      await apendIndividualRecords();
      await apendDealerRecords();
    }
    dataUpdate();
  }, [claims, dealerClaims, individualClaims]);
  useEffect(() => {
    setDealerClaims(claims.DealerClaimRecords);
    setIndividualClaims(claims.IndividualClaimRecords);

  }, [claims]);
  // Add useEffect to update local storage
  useEffect(() => {
    localStorage.setItem('claims', JSON.stringify(claims));
  }, [claims]);

  return (
    <GlobalContext.Provider value={{ claims, setClaims, activeClaims, activeDealerClaims, inactiveClaims, inactiveDealerClaims, expiredDealerClaims, expiredClaims, pendingClaims, pendingDealerClaims, setPendingClaims, setPendingDealerClaims, setExpiredClaims, setDealerClaims, setIndividualClaims, dealerClaims, individualClaims, previousLocation, setPreviousLocation }}>
      {children}
    </GlobalContext.Provider>
  );
};
