import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import '../../App.css';
import '../CSS/Details.css';
import axios from 'axios';
import { Spinner, ButtonSpinner } from '../../components/Spinner';
import { Button } from '../../components/Button';
import { jwtToken } from '../../Context/DataContext';
import { UserContext } from '../../Context/UserContext';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import { GlobalContext } from '../../Context/GlobalContext';
import ListGroup from 'react-bootstrap/ListGroup';
import { BsFillCheckCircleFill, BsFillXCircleFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import swal from 'sweetalert';
import ReClaimModel from '../../components/ReClaimModel';
import ImageSlider from './ImageSlider';
import OdometerGraph from './OdometerGraph';
import { UTCdateToFormattedDate } from '../../utils/DateAndTimeUtils';
import editPencil from '../../assets/img/editPencil.svg';
import { useReactToPrint } from 'react-to-print';
import DescriptionModel from '../../components/DescriptionModel';
import OfferAmountModel from '../../components/OfferAmountModel';
import UpdatePriceModal from '../../components/UpdatePriceModal';
import { getCurrentUTCTime, TimestampToUTC, TimestampToDate } from '../../utils/DateAndTimeUtils';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CarPrice from './CarPrice';
import Ratings from './Ratings';
import RegistrationDetails from './RegistrationDetails';
import LisenceDetails from './LisenceDetails';
import VehicleDetails from './VehicleDetails';
import InspectionDetails from './InspectionDetails';
import VehicleHistory from './VehicleHistory';
import VehicleSummary from './VehicleSummary';
import VehicleUsageDetails from './VehicleUsageDetails';
import TopFeatures from './TopFeatures';
import ExclamationIcon from '@mui/icons-material/ErrorOutline';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; // Background Check
import DriveEtaIcon from '@mui/icons-material/DriveEta'; // Vehicle Details
import DescriptionIcon from '@mui/icons-material/Description'; // Listing Details
import BuildIcon from '@mui/icons-material/Build'; // Engine
import TimelineIcon from '@mui/icons-material/Timeline'; // Odometer Readings with Years
import HistoryIcon from '@mui/icons-material/History'; // Vehicle History
import CommuteIcon from '@mui/icons-material/Commute'; // Vehicle Usage
import HowToRegIcon from '@mui/icons-material/HowToReg'; // Certificate of Fitness
import SpeedIcon from '@mui/icons-material/Speed';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import '../../assets/CSS/DetailField.css';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import VehicleReportModal from '../../components/VehicleReportModal';
import { ChatContext } from '../../Context/ChatContext';

export default function Details() {
  const { token } = useContext(jwtToken);
  const { userID, user } = useContext(UserContext);
  const { vehiData, setVehiData, claimObj, plateNumber, VIN } = useContext(VehicleDataContext);
  const { setClaimID, setPlateNumber, setBuyerID, setUserRole, setVIN } = useContext(ChatContext);
  const { setPreviousLocation } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const [odometer, setOdometerReadings] = useState([]);
  const [chart, setChart] = useState([]);
  const [listStatus, setListStatus] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);
  const [listReqLoading, setListReqLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [addWatchListLoading, setAddWatchListLoading] = useState(false);
  const [removeWatchListLoading, setRemoveWatchListLoading] = useState(false);
  const [generalDetails, setGeneralDetails] = useState(false);
  const [historyDetails, setHistoryDetails] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [claimRecord, setClaimRecord] = useState({});
  const replacerForUndefined = 'N/A';
  let plateNumberOrVIN = location.state?.plateNumberOrVIN || VIN;
  const [ratingsData, setRatingsData] = useState(null);
  const componentRef = useRef();
  //Outage scenario
  const [disableClaimPersonal, setDisableClaimPersonal] = useState(false);
  const [disableListOptionNZTA, setDisableListOptionNZTA] = useState(false);
  const [disableListOptionPPSR, setDisableListOptionPPSR] = useState(false);
  const [disableListOptionPersonal, setDisableListOptionPersonal] = useState(false);
  const [showVehicleReportModal, setShowVehicleReportModal] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const handleOpenModalVehicleReport = () => {
    if (!token) {
      history.push(`/signin`);
      setPreviousLocation(location.pathname);
    } else {
      setShowVehicleReportModal(true);
    }
  };
  const handleCloseModalVehicleReport = () => {
    setShowVehicleReportModal(false);

  };

  useEffect(() => {
    setIsPending(claimObj?.Status === 'Pending');
    const getActiveOutage = async () => {
      if (token) {
        try {
          const outageResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}get-outagebystatus`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const currentDateTime = getCurrentUTCTime();
          const isMatchingClaimPersonal = outageResponse.data.some(item => {
            if (item.API === 'CLAIM' && item.OutageType === 'Personal' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              return true;
            }
            return false;
          });
          const isMatchingListOutageNZTA = outageResponse.data.some(item => {
            if (item.API === 'LIST' && item.OutageType === 'NZTA' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              return true;
            }
            return false;
          });
          const isMatchingListOutagePPSR = outageResponse.data.some(item => {
            if (item.API === 'LIST' && item.OutageType === 'PPSR' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              return true;
            }
            return false;
          });
          const isMatchingListOutagePersonal = outageResponse.data.some(item => {
            if (item.API === 'LIST' && item.OutageType === 'Personal' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              return true;
            }
            return false;
          });

          if (isMatchingClaimPersonal) {
            setDisableClaimPersonal(true);
          }
          if (isMatchingListOutageNZTA) {
            setDisableListOptionNZTA(true);
          }
          if (isMatchingListOutagePPSR) {
            setDisableListOptionPPSR(true);
          }
          if (isMatchingListOutagePersonal) {
            setDisableListOptionPersonal(true);
          }

        } catch (error) {
          console.error("Error fetching", error);
        }
      }

    };
    getActiveOutage();
  }, [token]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${vehiData.vehicleDetails?.PlateNumber || vehiData.vehicleDetails?.VIN}-VehicleDetails.pdf`,
  });

  const [showModal, setShowModal] = useState(false);
  const [showOfferModal, setOfferShowModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModalOffer = () => {
    setOfferShowModal(true);
  };

  const handleCloseModalOffer = () => {
    setOfferShowModal(false);
  };

  const handleOpenModalPrice = () => {
    setShowPriceModal(true);
  };

  const handleCloseModalPrice = () => {
    setShowPriceModal(false);
  };

  const fetchVehicleData = useCallback(async () => {
    if (plateNumberOrVIN) {
      let vin;
      try {
        setInitialLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}historyDetails`, { userID, plateNumberOrVIN });
        vin = response.data.vehicleDetails?.VIN;
        if (response.data.statusDetails?.ClaimRecord) {
          setClaimRecord(response.data.statusDetails?.ClaimRecord);
        }
        setVehiData(response.data);
        arrangeDetails(response.data);
      } catch (error) {
        setVehiData({});
        if (error.response.status === 404 && error.response.data === 'Invalid plate number') {
          swal({
            text: 'Invalid Plate Number!',
            icon: 'error',
            dangerMode: true,
          });
        } else if (error.response.status === 404 && error.response.data === 'Invalid VIN') {
          swal({
            text: 'Invalid VIN!',
            icon: 'error',
            dangerMode: true,
          });
        } else {
          swal({
            text: 'Failed to retrieve vehicle details!',
            icon: 'error',
            dangerMode: true,
          });
        }
        history.push(`/`);
      } finally {
        setInitialLoading(false);
      }
      if (vin) {
        try {
          const ratingsResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}ratings`, { VIN: vin });
          const { Ratings, vehicleDetails } = ratingsResponse.data;
          setRatingsData(Ratings);
          if (vehicleDetails) {
            setVehiData(prevVehiData => ({
              ...prevVehiData,
              vehicleDetails: vehicleDetails,
            }));
          }
        } catch (error) {
          swal({
            text: 'Failed to retrieve ratings!',
            icon: 'error',
            dangerMode: true,
          });
        }
      }
    }
  }, [userID, plateNumberOrVIN]);

  useEffect(() => {
    fetchVehicleData();
  }, [fetchVehicleData]);

  const arrangeDetails = async (data) => {
    const { statusDetails, vehicleDetails } = data;
    const { ListStatus } = statusDetails;
    const { DetailsType, OdometerReadings } = vehicleDetails;
    if (ListStatus) {
      setListStatus('Listed');
    } else {
      setListStatus('NotListed');
    }
    if (DetailsType === 'InquireDetails') {
      setHistoryDetails(false);
      setGeneralDetails(true);
      setChart(OdometerReadings);
      const latestOdometerReading = OdometerReadings?.reduce((latest, reading) => {
        const readingDate = reading[0].ReadingDate;
        return readingDate > latest.ReadingDate ? reading : latest;
      }, OdometerReadings[0][0]);
      setOdometerReadings(latestOdometerReading);
    } else {
      setGeneralDetails(false);
      setHistoryDetails(true);
    }
  };

  //set the path for claim page
  function claimPath() {
    history.push(`/claim/${listStatus}`);
  }

  //set the path for claim page
  function listPath() {
    history.push("/claim/Not%20Listed?activeStep=3");
  }

  //function for sent request for list a vehicle
  async function listRequest() {
    swal({
      title: `List Request!`,
      text: `Confirm request to list vehicle ${plateNumber || VIN}?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        defeat: {
          text: 'Request',
          className: 'request-button' // Add a custom class name for the button
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setListReqLoading(true);
          try {
            const claimID = vehiData.statusDetails.ClaimRecord.ClaimID;
            const response = await axios.post(`${process.env.REACT_APP_API_COMMON}listRequests`,
              { userID, claimID }, { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
              setVehiData(prevVehiData => ({
                ...prevVehiData,
                statusDetails: {
                  ...prevVehiData.statusDetails,
                  UserRequestedToList: true,
                },
              }));
              swal({
                text: 'Requested successfully!',
                icon: 'success',
                dangerMode: true,
              });
            }
          } catch (error) {
            swal({
              text: 'Request unsuccessful. Please try again!',
              icon: 'error',
              dangerMode: true,
            });
          } finally {
            setListReqLoading(false);
          }
        }
      });
  }

  //function for add to watch listings
  async function addToWatchListings() {
    swal({
      title: `Add to Watch Listings!`,
      text: `Confirm adding ${plateNumber || VIN} to watch listings`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        defeat: {
          text: 'Add',
          className: 'request-button'
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setAddWatchListLoading(true);
          try {
            const claimID = vehiData.statusDetails.ClaimRecord.ClaimID;
            const buyerID = userID;
            const response = await axios.post(`${process.env.REACT_APP_API_COMMON}addWatchListings`,
              { buyerID, claimID }, { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
              setVehiData(prevVehiData => ({
                ...prevVehiData,
                statusDetails: {
                  ...prevVehiData.statusDetails,
                  AddWatchListingBtnStatus: false,
                  RemoveWatchListingBtnStatus: true,
                },
              }));
              swal({
                text: 'Added successfully!',
                icon: 'success',
                dangerMode: true,
              });
            }
          } catch (error) {
            swal({
              text: 'Adding to watched listing unsuccessful. Please try again!',
              icon: 'error',
              dangerMode: true,
            });
          } finally {
            setAddWatchListLoading(false);
          }
        }
      });
  }

  //function for remove watched listings
  async function removeWatchedListings() {
    swal({
      title: `Remove from Watch Listings!`,
      text: `Confirm removing ${plateNumber || VIN} from watch listings?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        defeat: {
          text: 'Remove',
          className: 'request-button'
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setRemoveWatchListLoading(true);
          try {
            const claimID = vehiData.statusDetails.ClaimRecord.ClaimID;
            const buyerID = userID;
            const response = await axios.post(`${process.env.REACT_APP_API_COMMON}removeFromWatchedListings`,
              { buyerID, claimID }, { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
              setVehiData(prevVehiData => ({
                ...prevVehiData,
                statusDetails: {
                  ...prevVehiData.statusDetails,
                  RemoveWatchListingBtnStatus: false,
                  AddWatchListingBtnStatus: true,
                },
              }));
              swal({
                text: 'Removed successfully!',
                icon: 'success',
                dangerMode: true,
              });
            }
          } catch (error) {
            swal({
              text: 'Removing unsuccessful. Please try again!',
              icon: 'error',
              dangerMode: true,
            });
          } finally {
            setRemoveWatchListLoading(false);
          }
        }
      });
  }

  //function for display the purchase model
  function purchaseDisplay() {
    history.push({
      pathname: '/purchase',
      state: {
        plateNumber: vehiData.vehicleDetails?.PlateNumber,
        VIN: vehiData.vehicleDetails?.VIN,
        offerID: vehiData.statusDetails?.offerRecord?.OfferID,
        amount: vehiData.statusDetails?.offerRecord?.Amount
      }
    });
  }

  function handlePendingClaim() {
    history.push({
      pathname: '/claim/Not%20Listed',
      state: {
        activeStep: 3,
      }
    });
  }

  function handleChat(vehicleDetails) {
    if (!token) {
      history.push(`/signin`);
      setPreviousLocation(location.pathname);
    } else {
      setClaimID(vehicleDetails.ClaimRecord.ClaimID);
      setPlateNumber(vehicleDetails.ClaimRecord.PlateNumber);
      setBuyerID(userID);
      setUserRole('buyer');
      setVIN(vehicleDetails.ClaimRecord.VIN);
      history.push('/message');
    }
  }

  const [description, setDescription] = useState('');
  const [rows, setRows] = useState(1);

  useEffect(() => {
    setDescription(vehiData.statusDetails?.ListRecord?.Description || '');
  }, [vehiData]);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    const textareaRows = Math.ceil(description.split('\n').length);
    setRows(textareaRows);
  }, [description]);

  const [showClaimButton, setShowClaimButton] = useState(false);
  const [showReclaimButton, setShowReclaimButton] = useState(false);
  const [showPendingClaimButton, setShowPendingClaimButton] = useState(false);
  const [showListButton, setShowListButton] = useState(false);
  const [showPurchaseButton, setShowPurchaseButton] = useState(false);
  const [showMessageButton, setShowMessageButton] = useState(false);
  const [showOfferButton, setShowOfferButton] = useState(false);
  const [showListRequestButton, setShowListRequestButton] = useState(false);
  const [showListRequestedButton, setShowListRequestedButton] = useState(false);
  const [showAddToWatchListingsButton, setShowAddToWatchListingsButton] = useState(false);
  const [editability, setEditability] = useState(false);

  useEffect(() => {
    setShowClaimButton(false);
    setShowReclaimButton(false);
    setShowPendingClaimButton(false);
    setShowListButton(false);
    setShowPurchaseButton(false);
    setShowMessageButton(false);
    setShowOfferButton(false);
    setShowListRequestButton(false);
    setShowListRequestedButton(false);
    setShowAddToWatchListingsButton(false);
    setEditability(false);
    const showDetailsButtons = async () => {
      if (token && vehiData?.statusDetails) {
        if (vehiData?.statusDetails?.AddWatchListingBtnStatus) {
          setShowAddToWatchListingsButton(true);
        }
        if (vehiData?.statusDetails?.ClaimRecord) {
          if (vehiData?.statusDetails?.ClaimRecord?.DealerID) {
            if (vehiData.statusDetails.ClaimRecord.DealerID === user.DealerID) {
              if (vehiData.statusDetails.ClaimRecord.Status === 'Expired') {
                setShowReclaimButton(true);
              } else if (vehiData.statusDetails.ClaimRecord.Status === 'Active') {
                if (!vehiData.statusDetails?.ListStatus) {
                  setShowListButton(true);
                } else {
                  setEditability(true);
                }
              } else if (vehiData.statusDetails.ClaimRecord.Status === 'Pending') {
                setShowPendingClaimButton(true);
              }
            } else {
              if (vehiData.statusDetails.ClaimRecord.Status === 'Expired') {
                setShowClaimButton(true);
              } else if (vehiData.statusDetails.ClaimRecord.Status === 'Active' || vehiData.statusDetails.ClaimRecord.Status === 'Pending') {
                setShowClaimButton(true);
                if (vehiData.statusDetails?.ListStatus) {
                  setShowMessageButton(true);
                  if (vehiData.statusDetails?.offerStatus) {
                    if (vehiData.statusDetails?.offerStatus && vehiData.statusDetails?.offerRecord?.Status === 'Accepted') {
                      setShowPurchaseButton(true);
                    }
                    if (vehiData.statusDetails.ClaimRecord.Status === 'Active' || vehiData.statusDetails.ClaimRecord.Status === 'Pending') {
                      if (vehiData.statusDetails?.offerStatus && vehiData.statusDetails?.offerRecord?.Status !== 'Accepted') {
                        setShowOfferButton(true);
                      }
                    }
                  } else {
                    setShowOfferButton(true);
                  }
                } else {
                  if (vehiData.statusDetails?.UserRequestedToList) {
                    setShowListRequestedButton(true);
                  } else {
                    setShowListRequestButton(true);
                  }
                }
              }
            }
          } else {
            if (vehiData.statusDetails?.ClaimRecord?.OwnerID === userID) {
              if (vehiData.statusDetails.ClaimRecord.Status === 'Expired') {
                setShowReclaimButton(true);
              } else if (vehiData.statusDetails.ClaimRecord.Status === 'Active') {
                if (!vehiData.statusDetails?.ListStatus) {
                  setShowListButton(true);
                } else {
                  setEditability(true);
                }
              } else if (vehiData.statusDetails.ClaimRecord.Status === 'Pending') {
                setShowPendingClaimButton(true);
              }
            } else {
              setShowClaimButton(true);
              if (vehiData.statusDetails?.ListStatus) {
                setShowMessageButton(true);
                if (vehiData.statusDetails?.offerStatus && vehiData.statusDetails?.offerRecord?.Status === 'Accepted') {
                  setShowPurchaseButton(true);
                }
                if (vehiData.statusDetails.ClaimRecord.Status === 'Active' || vehiData.statusDetails.ClaimRecord.Status === 'Pending') {
                  if (vehiData.statusDetails?.offerStatus) {
                    if (vehiData.statusDetails?.offerRecord?.Status !== 'Accepted') {
                      setShowOfferButton(true);
                    }
                  } else {
                    setShowOfferButton(true);
                  }
                }
              } else {
                if (vehiData.statusDetails?.UserRequestedToList) {
                  setShowListRequestedButton(true);
                } else {
                  setShowListRequestButton(true);
                }
              }
            }
          }
        } else {
          setShowClaimButton(true);
        }
      };
      if (!token) {
        setShowClaimButton(true);
        setShowMessageButton(true);
        setShowOfferButton(true);
        setShowListRequestButton(true);
        setShowAddToWatchListingsButton(true);
      }
    };
    showDetailsButtons();
  }, [vehiData]);

  let iframeSrc;
  const plateOrvin = plateNumber ? `plate=${plateNumber}` : `vin=${VIN}`;

  iframeSrc = `https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2F${process.env.REACT_APP_CUSTOM_DOMAIN_1}%3F${plateOrvin}&layout&size&appId=1494294604316540&width=77&height=20`;

  return (
    <div className="app p-spacing" style={{ minHeight: '100%', position: 'relative' }}>
      {initialLoading && !offerLoading && !listReqLoading && !addWatchListLoading && !removeWatchListLoading ? (<Spinner></Spinner>) : (
        <>
          <div className="container m-spacing reducepd1">
            <div className="my-3" ref={componentRef}>
              <div className="print-only">
                <div className='align-right'>
                  VEHICLE INFORMATION SHEET
                </div>
                <div className="web-address">
                  <a href="https://wakaonline.nz">Visit Waka Online</a>
                </div>
              </div>
              {/* Basic details */}
              <div className='avoid-breakInside'>
                {vehiData.statusDetails && vehiData.statusDetails.ClaimStatus && (
                  <div className='content-container d-flex align-content-center'>
                    {vehiData.statusDetails?.DealerDetails && (
                      <div className="logoandtopic" style={{ marginBottom: '15px' }}>
                        {vehiData.statusDetails.DealerDetails.LogoURL &&
                          <div className='logo-container'>
                            <img src={vehiData.statusDetails.DealerDetails.LogoURL} alt="Logo" className="logo-image" />
                          </div>
                        }
                        <h3 className='details_fc'>{vehiData.statusDetails.DealerDetails.CompanyName}</h3>
                      </div>
                    )}
                  </div>
                )}
                <div className='container basics'>
                  <div className='content-container d-flex align-content-center'>
                    <div className='details d-flex flex-wrap justify-content-center col-12'>
                      {vehiData.vehicleDetails?.Make && (
                        <div className="col-4 col-md-2 sm-6 xs-6 d-flex justify-content-center align-content-center">
                          <p className="highL" style={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                            {/* <span style={{ whiteSpace: 'nowrap' }}>Make: </span> */}
                            <IconButton>
                              <DriveEtaIcon />
                            </IconButton>
                            <span style={{ whiteSpace: 'nowrap' }}>{vehiData.vehicleDetails?.Make}</span>
                          </p>
                        </div>
                      )}
                      {vehiData.vehicleDetails?.Model && (
                        <div className="col-4 col-md-2 sm-6 xs-6 d-flex justify-content-center">
                          <p className="highL" style={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                            {/* <span style={{ whiteSpace: 'nowrap' }}>Model: </span> */}
                            <IconButton>
                              <CommuteIcon />
                            </IconButton>
                            <span style={{ whiteSpace: 'nowrap' }}>{vehiData.vehicleDetails?.Model}</span>
                          </p>
                        </div>
                      )}
                      {vehiData.statusDetails?.ListStatus && vehiData.statusDetails.ListRecord?.Price && (
                        <div className="col-4 col-md-2 sm-4 xs-4 d-flex justify-content-center">
                          <p className="highL" style={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                            {/* <span style={{ whiteSpace: 'nowrap' }}>Price: </span> */}
                            <IconButton>
                              <LocalAtmIcon />
                            </IconButton>
                            <span style={{ whiteSpace: 'nowrap' }}>${vehiData.statusDetails.ListRecord.Price}</span>
                          </p>
                        </div>
                      )}
                      {odometer?.Reading && (
                        <div className="col-4 col-md-2 sm-4 xs-4 d-flex justify-content-center">
                          <p className="highL" style={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                            {/* <span style={{ whiteSpace: 'nowrap' }}>Odometer: </span> */}
                            <IconButton>
                              <SpeedIcon />
                            </IconButton>
                            <span style={{ whiteSpace: 'nowrap' }}>{odometer?.Reading}Km</span>
                          </p>
                        </div>
                      )}
                      {vehiData.vehicleDetails?.BodyStyle && (
                        <div className="col-4 col-md-2 col-sm-4 col-xs-4 d-flex justify-content-center">
                          <p className="highL" style={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                            {/* <span style={{ whiteSpace: 'nowrap' }}>Body Style: </span> */}
                            <IconButton>
                              <DirectionsCarIcon />
                            </IconButton>
                            <span style={{ whiteSpace: 'nowrap' }}>{vehiData.vehicleDetails?.BodyStyle}</span>
                          </p>
                        </div>
                      )}
                      {vehiData.statusDetails?.ClaimRecord && (
                        <div className="col-4 col-md-2 sm-4 xs-4 d-flex justify-content-center">
                          <p className="highL" style={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                            <IconButton>
                              <LocationOnIcon />
                            </IconButton>
                            {/* <span style={{ whiteSpace: 'nowrap' }}>Location: </span> */}
                            <span style={{ whiteSpace: 'nowrap' }}>{vehiData.statusDetails.ClaimRecord?.Location}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <header id="header">
                  <div className="row topdetails01">
                    <div className="col-12 col-md-8 sm-6 xs-6 avoid-breakInside shrink-table" >
                      <Card sx={{ maxWidth: 1000, maxHeight: 1000 }}>
                        <CardMedia><ImageSlider claim={claimRecord || {}} /></CardMedia>
                      </Card>
                    </div>
                    <div className="col-12 col-md-4 sm-6 xs-6 avoid-breakInside shrink-table">
                      <TopFeatures vehicleDetails={vehiData.vehicleDetails} odometer={odometer} />
                      {vehiData.vehicleDetails?.PlateNumber &&
                        <div className="numberPlate">
                          <p1>
                            {vehiData.vehicleDetails.PlateNumber}
                          </p1>
                        </div>}
                    </div>
                  </div>
                </header>
              </div>
              <div className='exclude-from-print' >
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                  {showClaimButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Tooltip
                        title={disableClaimPersonal ? 'Waka Online NZ Reported planned outage. The claim service is currently unavailable. Please try again later.' : ''}
                        arrow={true}
                        followCursor
                      >
                        <Button buttonStyle="btn--outline"
                          onClick={() => {
                            if (!token) {
                              history.push(`/signin`);
                              setPreviousLocation(location.pathname);
                            } else {
                              claimPath();
                            }
                          }}
                        >
                          List
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                  {showPurchaseButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button onClick={purchaseDisplay} buttonStyle="btn--outline">
                        Purchase
                      </Button>
                    </div>
                  )}
                  {showReclaimButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button onClick={() => setShowClaim(true)} buttonStyle="btn--outline">
                        Re-Claim Vehicle
                      </Button>
                    </div>
                  )}
                  {showPendingClaimButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button onClick={handlePendingClaim} buttonStyle="btn--outline">
                        Claim Vehicle
                      </Button>
                    </div>
                  )}
                  {showMessageButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button buttonStyle="btn--outline" onClick={() => handleChat(vehiData.statusDetails)}>Message to Seller</Button>
                    </div>
                  )}
                  {showOfferButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button onClick={() => {
                        if (!token) {
                          history.push(`/signin`);
                          setPreviousLocation(location.pathname);
                        } else {
                          handleOpenModalOffer();
                        }
                      }}
                        buttonStyle="btn--outline" disabled={offerLoading ? true : false}>
                        {offerLoading ? <ButtonSpinner /> : 'Offer Amount'}
                      </Button>
                    </div>
                  )}
                  {showListRequestedButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button disabled buttonStyle="btn--outline">
                        Requested
                      </Button>
                    </div>
                  )}
                  {showListRequestButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button onClick={() => {
                        if (!token) {
                          history.push(`/signin`);
                          setPreviousLocation(location.pathname);
                        } else {
                          listRequest();
                        }
                      }}
                        buttonStyle="btn--outline" disabled={listReqLoading ? true : false}>
                        {listReqLoading ? <ButtonSpinner /> : 'Request to List'}
                      </Button>
                    </div>
                  )}
                  {showListButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Tooltip
                        title={disableListOptionPersonal ? 'Waka Online NZ Reported planned outage. The listing service is currently unavailable. Please try again later.' : (
                          disableListOptionNZTA && disableListOptionPPSR ? 'NZTA/PPSR Reported planned outage. The listing service is currently unavailable. Please try again later.' : (
                            disableListOptionNZTA ? 'NZTA Reported planned outage. The listing service is currently unavailable. Please try again later.' : (
                              disableListOptionPPSR ? 'PPSR Reported planned outage. The listing service is currently unavailable. Please try again later.' : ''
                            )
                          )
                        )}
                        arrow={true}
                        followCursor
                      >
                        <Button onClick={listPath} buttonStyle="btn--outline" disabled={disableListOptionPersonal || disableListOptionNZTA || disableListOptionPPSR}>&emsp;&emsp;&emsp;&emsp;List&emsp;&emsp;&emsp;&emsp;</Button>
                      </Tooltip>
                    </div>
                  )}
                  {showAddToWatchListingsButton && (
                    <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                      <Button onClick={() => {
                        if (!token) {
                          history.push(`/signin`);
                          setPreviousLocation(location.pathname);
                        } else {
                          addToWatchListings();
                        }
                      }}
                        buttonStyle="btn--outline" disabled={addWatchListLoading ? true : false}>
                        {addWatchListLoading ? <ButtonSpinner /> : 'Add to Watch Listings'}
                      </Button>
                    </div>
                  )}
                  {token && vehiData.statusDetails && (
                    <>
                      {vehiData.statusDetails.RemoveWatchListingBtnStatus && (
                        <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                          <Button onClick={removeWatchedListings} buttonStyle="btn--outline" disabled={removeWatchListLoading ? true : false}>
                            {removeWatchListLoading ? <ButtonSpinner /> : 'Remove from Watched Listings'}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  <div className="btn1 mt-4" style={{ whiteSpace: 'nowrap' }}>
                    <Button onClick={handleOpenModalVehicleReport} buttonStyle="btn--outline">
                      Vehicle report
                    </Button>
                  </div>
                </div>

              </div>

              <div className='row' style={{ margin: '30px 0px 20px 0px' }}>
                <div className='col-md-6 col-sm-6 col-12 d-flex fb-share'>
                  {vehiData.vehicleDetails && (
                    <>
                      {editability && (
                        // eslint-disable-next-line jsx-a11y/iframe-has-title
                        <iframe
                          src={iframeSrc}
                          height="20"
                          style={{ border: 'none', overflow: 'hidden' }}
                          scrolling="no"
                          frameBorder="0"
                          allowFullScreen={true}
                          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        ></iframe>
                      )}
                    </>
                  )}
                </div>
                <div className='col-md-6 col-sm-6 col-12 d-flex visitor-count'>
                  {vehiData.statusDetails?.VisitorCountValue && (
                    <p className='details_fc' style={{ fontSize: '12px', margin: '0' }}>Visitor count: {vehiData.statusDetails.VisitorCountValue}</p>
                  )}
                </div>
              </div>
              {/* </div> */}

              <div className='exclude-from-print'>
                {vehiData.vehicleDetails && (
                  <>
                    {(vehiData.statusDetails?.ListStatus && (vehiData.statusDetails.ListRecord?.Description || editability)) && (
                      <>
                        <div className='avoid-breakInside'>
                          <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="A comprehensive narrative providing detailed information about the vehicle, including its features, condition, history, and any unique characteristics. The vehicle description aims to offer potential buyers or users a thorough understanding of the vehicle's attributes and qualities." arrow>
                              <IconButton><DescriptionIcon /></IconButton></Tooltip>
                            <h4 className='details_fc' >Description</h4>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-lg-13 col-md-12 col-sm-12 col-xs-12 background-row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 detail-content-description customListGroup">
                                <textarea
                                  value={description}
                                  onChange={handleDescriptionChange}
                                  readOnly={true}
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    border: "1px solid #ccc",
                                    width: "100%",
                                    resize: "none",  // Disable resizing
                                    padding: "10px", // Add padding for consistency
                                    borderRadius: "5px",
                                    position: 'relative',
                                    // overflowY: 'hidden',
                                    overflowY: "scroll"
                                  }}
                                  rows={rows}
                                />
                                {editability && (
                                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton aria-label="edit" onClick={handleOpenModal} className="editIcon customListGroup">
                                      <EditIcon className="editBotton" />
                                      {vehiData.statusDetails.ListRecord?.DescriptionStatus === 'Pending' ?
                                        'Edit Description (waiting for approval)' : ''}
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <CarPrice vin={VIN} />

              <div className='exclude-from-print'>
                <div className="row">
                  {vehiData.statusDetails && (
                    <>
                      {vehiData.statusDetails.DealerDetails && vehiData.statusDetails.DealerDetails.ContactPerson && vehiData.statusDetails.DealerDetails.ContactNumber && (
                        <>
                          <div className="col-lg-13 col-md-12 col-sm-12 col-xs-12 background-row">
                            <h4 className='details_fc' >Contact Details of {vehiData.statusDetails.DealerDetails.CompanyName}</h4>
                            <hr />
                            <ListGroup horizontal>
                              <ListGroup.Item className="col-lg-12 col-md-12 col-sm-12 col-xs-12 detail-content-description">
                                <div className="data_sou" style={{ fontSize: "14px", color: "gray" }}>
                                  <p className="data_sou" style={{ fontSize: "14px", color: "gray" }}>
                                    <strong>Contact Person: </strong>{vehiData.statusDetails.DealerDetails.ContactPerson}<br />
                                    <strong>Contact Number: </strong>{vehiData.statusDetails.DealerDetails.ContactNumber}<br />
                                  </p>
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </div>
                        </>
                      )}
                      <div className='avoid-breakInside'>
                        {vehiData.statusDetails.DealerServices && vehiData.statusDetails.DealerServices.length !== 0 && (
                          <>
                            <div className="col-lg-13 col-md-12 col-sm-12 col-xs-12 background-row">
                              <h4 className='details_fc' >Services from {vehiData.statusDetails.DealerDetails.CompanyName}</h4>
                              <hr />
                              <ListGroup horizontal>
                                <ListGroup.Item className="col-lg-12 col-md-12 col-sm-12 col-xs-12 detail-content-description">
                                  <div className="data_sou" style={{ fontSize: "14px", color: "gray" }}>
                                    {vehiData.statusDetails.DealerServices
                                      .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
                                      .map((service, index) => (
                                        <p key={index} className="data_sou" style={{ fontSize: "14px", color: "gray" }}>
                                          {/* Display promotion details */}
                                          <strong style={{ fontSize: 16 }}>{service.ServiceName}</strong><br />
                                          <textarea
                                            value={service.Description}
                                            readOnly={true}
                                            style={{
                                              fontSize: "14px",
                                              color: "gray",
                                              border: "1px solid #ccc",
                                              width: "100%",
                                              resize: "none",  // Disable resizing
                                              padding: "10px", // Add padding for consistency
                                              borderRadius: "5px",
                                              position: 'relative',
                                              overflowY: "scroll"
                                            }}
                                            rows={Math.ceil(service.Description.split('\n').length)}
                                          />
                                          Cost is ${service.Cost}
                                        </p>
                                      ))}
                                  </div>
                                </ListGroup.Item>
                              </ListGroup>
                            </div>
                          </>
                        )}
                      </div>
                      <div className='avoid-breakInside'>
                        {vehiData.statusDetails.DealerPromotions && vehiData.statusDetails.DealerPromotions.length !== 0 && (
                          <>
                            <div className="col-lg-13 col-md-12 col-sm-12 col-xs-12 background-row">
                              <h4 className='details_fc' >Promotions from {vehiData.statusDetails.DealerDetails.CompanyName}</h4>
                              <hr />
                              <ListGroup horizontal>
                                <ListGroup.Item className="col-lg-12 col-md-12 col-sm-12 col-xs-12 detail-content-description">
                                  <div className="data_sou" style={{ fontSize: "14px", color: "gray" }}>
                                    {vehiData.statusDetails.DealerPromotions
                                      .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
                                      .map((promotion, index) => (
                                        <p key={index} className="data_sou" style={{ fontSize: "14px", color: "gray" }}>
                                          {/* Display promotion details */}
                                          <strong style={{ fontSize: 16 }}>{promotion.PromotionName}</strong><br />
                                          <textarea
                                            value={promotion.Description}
                                            readOnly={true}
                                            style={{
                                              fontSize: "14px",
                                              color: "gray",
                                              border: "1px solid #ccc",
                                              width: "100%",
                                              resize: "none",  // Disable resizing
                                              padding: "10px", // Add padding for consistency
                                              borderRadius: "5px",
                                              position: 'relative',
                                              overflowY: "scroll"
                                            }}
                                            rows={Math.ceil(promotion.Description.split('\n').length)}
                                          />
                                          Valid until {TimestampToDate(promotion.ExpiryDate)}
                                        </p>
                                      ))}
                                  </div>
                                </ListGroup.Item>
                              </ListGroup>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className='avoid-breakInside shrink-table'>
                  <br />
                  {vehiData.vehicleDetails && (
                    <>
                      <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Comprehensive investigation into an individual's or entity's history, often focusing on criminal records, employment history, and financial activities." arrow>
                          <IconButton><VerifiedUserIcon /></IconButton></Tooltip>
                        <h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Background Check</h4>
                      </div>
                      <hr />
                      <div className='makeCol'>
                        <div className="row">
                          {/* Money owed by vehicle check result */}
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 background-row">
                            <ListGroup horizontal>
                              <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 col-xs-8 detail-content"><h6 className='det_head'>Money Owing</h6>
                                <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
                                  Date - {vehiData.vehicleDetails?.MoneyOwingCheckDate ? UTCdateToFormattedDate(vehiData.vehicleDetails.MoneyOwingCheckDate) : 'Unavailable'}
                                </p>
                                <p className="data_sou" style={{ fontSize: "12px", color: "gray" }}>Source - Personal Properties Securities Register</p>
                              </ListGroup.Item>
                              {vehiData.vehicleDetails?.MoneyOwingStatus === 'false' ? (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 passed d-flex align-items-center justify-content-center" >
                                  <BsFillCheckCircleFill /> &nbsp; Passed
                                </ListGroup.Item>
                              ) : vehiData.vehicleDetails?.MoneyOwingStatus === 'true' ? (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 failed d-flex align-items-center justify-content-center">
                                  <BsFillXCircleFill className='background-check-svg' /> &nbsp; Failed
                                </ListGroup.Item>
                              ) : (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4  unavailable d-flex align-items-center justify-content-center">
                                  <BsFillQuestionCircleFill className='background-check-svg' /> &nbsp; Unavailable
                                </ListGroup.Item>
                              )}
                            </ListGroup>
                          </div>
                          {/* stolen check result */}
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 background-row">
                            <ListGroup horizontal>
                              <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 detail-content">
                                <h6 className='det_head'>Reported Stolen</h6>
                                <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }} >
                                  Date - {vehiData.vehicleDetails?.ReportedStolenCheckDate ? UTCdateToFormattedDate(vehiData.vehicleDetails.ReportedStolenCheckDate) : 'Unavailable'}
                                </p>
                                <p className="data_sou" style={{ fontSize: "12px", color: "gray" }} >Source - Waka Kotahi</p>
                              </ListGroup.Item>
                              {vehiData.vehicleDetails?.ReportedStolen === 'false' ? (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 passed d-flex align-items-center justify-content-center">
                                  <BsFillCheckCircleFill /> &nbsp; Passed
                                </ListGroup.Item>
                              ) : vehiData.vehicleDetails?.ReportedStolen === 'true' ? (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4  failed d-flex align-items-center justify-content-center">
                                  <BsFillXCircleFill /> &nbsp; Failed
                                </ListGroup.Item>
                              ) : (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 unavailable d-flex align-items-center justify-content-center">
                                  <BsFillQuestionCircleFill /> &nbsp; Unavailable
                                </ListGroup.Item>
                              )}
                            </ListGroup>
                          </div>
                        </div>
                        {/* Recall check result */}
                        <div className="row mb-4">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 background-row">
                            <div>
                              <ListGroup horizontal>
                                <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 col-xs-8 detail-content"><h6 className='det_head' >Recall Status</h6>
                                  <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
                                    Date - {vehiData.vehicleDetails?.RecalledStatusCheckDate ? UTCdateToFormattedDate(vehiData.vehicleDetails.RecalledStatusCheckDate) : 'Unavailable'}
                                  </p>
                                  <p className="data_sou" style={{ fontSize: "12px", color: "gray" }}>Source - Waka Kotahi</p>
                                </ListGroup.Item>
                                {vehiData.vehicleDetails?.RecalledStatus === 'Not known as recalled' ? (
                                  <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 passed d-flex align-items-center justify-content-center">
                                    <BsFillCheckCircleFill /> &nbsp; Passed
                                  </ListGroup.Item>
                                ) : vehiData.vehicleDetails?.RecalledStatus === 'Known recall with reference' ? (
                                  <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 failed d-flex align-items-center justify-content-center">
                                    <BsFillXCircleFill /> &nbsp; Failed
                                  </ListGroup.Item>
                                ) : (
                                  <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 unavailable d-flex align-items-center justify-content-center">
                                    <BsFillQuestionCircleFill /> &nbsp; Unavailable
                                  </ListGroup.Item>
                                )}
                              </ListGroup>
                            </div>
                          </div>
                          {/* Imported Damage check result */}
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12  background-row">
                            <ListGroup horizontal>
                              <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 col-xs-8 detail-content"><h6 className='det_head' >Imported Damage</h6>
                                <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
                                  Date - {vehiData.vehicleDetails?.UpdatedAt ? UTCdateToFormattedDate(vehiData.vehicleDetails.UpdatedAt) : 'Unavailable'}
                                </p>
                                <p className="data_sou" style={{ fontSize: "12px", color: "gray" }}>Source - Waka Kotahi</p>
                              </ListGroup.Item>
                              {vehiData.vehicleDetails?.IsImportedDamaged === 'false' ? (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 passed d-flex align-items-center justify-content-center">
                                  <BsFillCheckCircleFill /> &nbsp; Passed
                                </ListGroup.Item>
                              ) : vehiData.vehicleDetails?.IsImportedDamaged === 'true' ? (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 failed d-flex align-items-center justify-content-center">
                                  <BsFillXCircleFill /> &nbsp; Failed
                                </ListGroup.Item>
                              ) : (
                                <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 unavailable d-flex align-items-center justify-content-center">
                                  <BsFillQuestionCircleFill /> &nbsp; Unavailable
                                </ListGroup.Item>
                              )}
                            </ListGroup>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* ratings strats */}
                <div className='avoid-breakInside shrink-table'>
                  <Ratings ratingsData={ratingsData} />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 sm-6 xs-6 avoid-breakInside" >
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Specific information about a vehicle, including its make, model, year of manufacture, VIN (Vehicle Identification Number), and other relevant details." arrow>
                          <IconButton><DriveEtaIcon /></IconButton></Tooltip>
                        <h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Vehicle Details</h4>
                      </div>
                      <hr />
                      <VehicleDetails vehicleDetails={vehiData.vehicleDetails} />
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 sm-6 xs-6">
                  {generalDetails && vehiData.statusDetails?.ListStatus && (
                    <div className="row avoid-breakInside">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Detailed information provided in a listing, such as product specifications, condition, pricing, and any additional features or services included." arrow>
                          <IconButton><DescriptionIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Listing Details</h4>
                      </div>
                      <hr />
                      <>
                        {vehiData.statusDetails?.ClaimRecord && (
                          <div className="text-cell">
                            <div className="part1">Vehicle Location</div>
                            <div className="part2"> {vehiData.statusDetails.ClaimRecord?.Location || replacerForUndefined}</div>
                          </div>
                        )}
                        {vehiData.statusDetails.ListRecord?.Price &&
                          <div className="text-cell">
                            <div className="part1">Vehicle Price</div>
                            <div className="part2">${vehiData.statusDetails.ListRecord.Price}{editability ? <img src={editPencil} alt="editPencil" onClick={handleOpenModalPrice} style={{ marginLeft: 10, cursor: 'pointer', width: '20px', borderRadius: '0px' }} /> : null}</div>
                          </div>
                        }
                        {vehiData.statusDetails?.ListRecord?.cinFile !== "CIN not uploaded" &&
                          <div className="text-cell">
                            <div className="part1">Consumer Information Notice</div>
                            <div className="part2"><a href={vehiData.statusDetails.ListRecord.signedURLCinFile} target="_blank" rel="noopener noreferrer">CIN File</a></div>
                          </div>
                        }
                      </>
                    </div>
                  )}
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row avoid-breakInside">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="The core component of a vehicle responsible for generating power, typically described by factors like horsepower, fuel type, and displacement." arrow>
                          <IconButton><BuildIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Engine</h4>
                      </div>
                      <hr />
                      <div className="text-cell">
                        <div className="part1">Engine Number</div>
                        <div className="part2 engineNo">{vehiData.vehicleDetails?.EngineNumber || replacerForUndefined}</div>
                      </div>
                      <div className="text-cell">
                        <div className="part1">Fuel Type</div>
                        <div className="part2">{vehiData.vehicleDetails?.FuelType || replacerForUndefined}</div>
                      </div>
                      <div className="text-cell">
                        <div className="part1">CCRating</div>
                        <div className="part2">{vehiData.vehicleDetails?.CCRating || replacerForUndefined}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 sm-6 xs-6 avoid-breakInside" >
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row">
                      <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="The official process of enrolling a vehicle with the relevant government authority, confirming ownership, and obtaining necessary documentation." arrow>
                          <IconButton><BuildIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Registration</h4>
                      </div>
                      <hr />
                      <RegistrationDetails vehicleDetails={vehiData.vehicleDetails} />
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 sm-6 xs-6 avoid-breakInside shrink-table">
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row">
                      <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Official permission granted by a government authority to operate a vehicle, indicating that the holder has met required criteria and passed examinations." arrow>
                          <IconButton><ExclamationIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;License</h4>
                      </div>
                      <hr />
                      <LisenceDetails vehicleDetails={vehiData.vehicleDetails} />
                    </div>
                  )}
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row avoid-breakInside headin">
                      {vehiData.vehicleDetails?.SubjectToCOF === "true" ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Official document certifying that a vehicle meets specific safety and emission standards, typically issued after a successful inspection. It is crucial for legal operation in many jurisdictions and may require periodic renewal." arrow>
                            <IconButton><HowToRegIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Certificate of Fitness</h4>
                        </div>
                      ) : vehiData.vehicleDetails?.SubjectToWOF === "true" ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Certificate confirming that a vehicle meets safety and emission standards, often required for legal operation in certain jurisdictions" arrow>
                            <IconButton><HowToRegIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Warrant of Fitness</h4>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Thorough examination of a vehicle's components, systems, and overall condition to identify any issues or maintenance needs. Inspections are often conducted for safety and compliance purposes." arrow>
                            <IconButton><HowToRegIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Inspection</h4>
                        </div>
                      )}
                      <hr />
                      <InspectionDetails vehicleDetails={vehiData.vehicleDetails} />
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 sm-6 xs-6 avoid-breakInside shrink-table" >
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row">
                      <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Recorded mileage of a vehicle at specific points in time, indicating usage and helping assess the vehicle's condition and potential wear." arrow>
                          <IconButton><TimelineIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Odometer Readings with Years</h4>
                      </div>
                      <hr />
                      <div className='odometergraph' style={{ height: '500px' }}>
                        <OdometerGraph className="odometergraph" chart={chart} style={{ height: '300px' }} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 sm-6 xs-6">
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row avoid-breakInside">
                      <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Documentation detailing a vehicle's past, including ownership transfers, accidents, repairs, and other events that may impact its overall condition." arrow>
                          <IconButton><HistoryIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Vehicle History</h4>
                      </div>
                      <hr />
                      <VehicleHistory vehicleDetails={vehiData.vehicleDetails} odometer={odometer} />
                    </div>
                  )}
                  {generalDetails && vehiData.vehicleDetails && (
                    <div className="row avoid-breakInside">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Information about how a vehicle has been used, such as personal, commercial, or rental use, providing insights into wear and tear patterns." arrow>
                          <IconButton><CommuteIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Vehicle Usage</h4>
                      </div>
                      <hr />
                      <VehicleUsageDetails vehicleDetails={vehiData.vehicleDetails} />
                    </div>
                  )}
                </div>
              </div>

              {historyDetails && vehiData.vehicleDetails && (
                <div className="row">
                  <div className="col-12 col-md-6 sm-6 xs-6 avoid-breakInside">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="A concise overview of essential information about a vehicle, including its make, model, year of manufacture, key features, and overall condition. The vehicle summary provides a quick reference for potential buyers or users seeking essential details before further exploration" arrow>
                        <IconButton><ExclamationIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Vehicle Summary</h4>
                    </div>
                    <hr />
                    <VehicleSummary vehicleDetails={vehiData.vehicleDetails} />
                  </div>
                  <div className="col-12 col-md-6 sm-6 xs-6 avoid-breakInside">
                    {vehiData.statusDetails?.ListStatus && (
                      <div className="row avoid-breakInside">
                        <div className="col-12 col-md-12 sm-12 xs-12 avoid-breakInside">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Detailed information provided in a listing, such as product specifications, condition, pricing, and any additional features or services included." arrow>
                              <IconButton><DescriptionIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Listing Details</h4>
                          </div>
                          <hr />
                          <>
                            {vehiData.statusDetails?.ClaimRecord && (
                              <div className="text-cell">
                                <div className="part1">Vehicle Location</div>
                                <div className="part2"> {vehiData.statusDetails.ClaimRecord?.Location || replacerForUndefined}</div>
                              </div>
                            )}
                            {vehiData.statusDetails?.ListRecord?.Price &&
                              <div className="text-cell">
                                <div className="part1">Vehicle Price</div>
                                <div className="part2">${vehiData.statusDetails.ListRecord.Price}{editability ? <img src={editPencil} alt="editPencil" onClick={handleOpenModalPrice} style={{ marginLeft: 10, cursor: 'pointer' }} /> : null}</div>
                              </div>
                            }
                            {vehiData.statusDetails?.ListRecord?.cinFile !== "CIN not uploaded" &&
                              <div className="text-cell">
                                <div className="part1">Consumer Information Notice</div>
                                <div className="part2"><a href={vehiData.statusDetails.ListRecord.signedURLCinFile} target="_blank" rel="noopener noreferrer">CIN File</a></div>
                              </div>
                            }
                          </>
                        </div>
                      </div>
                    )}
                    <div className="row avoid-breakInside">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Information about how a vehicle has been used, such as personal, commercial, or rental use, providing insights into wear and tear patterns." arrow>
                          <IconButton><CommuteIcon /></IconButton></Tooltip><h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>&nbsp;Vehicle Usage</h4>
                      </div>
                      <hr />
                      <VehicleUsageDetails vehicleDetails={vehiData.vehicleDetails} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handlePrint} buttonStyle="btn--print">
                Download Report
              </Button>
            </div>
          </div>
          <div className='exclude-from-print'>
            <br />
            <br />
          </div>
          {/* Re Claim model call*/}
          <ReClaimModel
            show={showClaim}
            claimDetails={claimObj}
            reclaimmessage={'Your claim has expired. You have to re-claim your vehicle'}
            setShowClaim={setShowClaim}
          />
          {/* Render the description component */}
          <DescriptionModel
            show={showModal}
            description={vehiData.statusDetails?.ListRecord?.Description}
            setShowDescription={handleCloseModal}
            userID={userID}
            ownerID={vehiData.statusDetails?.ClaimRecord?.OwnerID}
            claimID={vehiData.statusDetails?.ClaimRecord?.ClaimID}
            editability={editability}
          />
          <OfferAmountModel
            show={showOfferModal}
            setShowModalOffer={handleCloseModalOffer}
            offerAmount={vehiData.statusDetails?.ListRecord?.Price ? vehiData.statusDetails?.ListRecord?.Price.toString() : ''}
          />
          <UpdatePriceModal
            show={showPriceModal}
            setShowModalPrice={handleCloseModalPrice}
            prevPrice={vehiData.statusDetails?.ListRecord?.Price}
          />
          <VehicleReportModal
            show={showVehicleReportModal}
            setShowVehicleDetails={handleCloseModalVehicleReport}
            vin={VIN}
            plate={plateNumber}
          />
        </>
      )}
    </div >
  );
}
